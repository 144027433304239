@keyframes flashing {
  0%, 100% {
    background-color: #a1d435;
  }
  50% {
    background-color: #fff600;
  }
}
@keyframes pointing {
  0%, 20%, 30%, 100%  {
    transform: rotate(0deg);
  }
  15%, 25% {
    transform: rotate(3deg);
  }
}
@keyframes appearing {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(.95);
  }
}
@keyframes floating {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}
@keyframes floatingLight {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(.5rem);
  }
}
@keyframes glowing {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}
@keyframes heartBeat {
  0%, 40%, 80%, 100% {
    transform: scale(1);
  }
  20%, 60% {
    transform: scale(1.1);
  }
}
@keyframes heartBeatLow {
  0%, 40%, 80%, 100% {
    transform: scale(1);
  }
  20%, 60% {
    transform: scale(1.05);
  }
}
@keyframes ping {
  0%, 20%, 40%, 100% {
    transform: translateY(0);
  }
  10%, 30% {
    transform: translateY(-1rem);
  }
}
@keyframes ping-horizon {
  0%, 20%, 40%, 100% {
    transform: rotate(-90deg) translateY(0);
  }
  10%, 30% {
    transform: rotate(-90deg) translateY(-1rem);
  }
}
@keyframes scaling {
  0%, 100% {
    transform: scale(1) rotate(-8deg);
  }
  50% {
    transform: scale(1.1) rotate(-8deg);
  }
}
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
  }
}
@keyframes won {
  0%, 100% {
    transform: scale(1);
  }
  30%, 70% {
    transform: scale(1.2);
  }
}
@keyframes shakeLittle {
  2% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  4% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  6% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  8% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  10% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  12% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  14% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  16% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  18% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  20% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  22% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  24% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  26% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  28% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  30% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  32% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  34% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  36% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  38% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  40% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  42% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  44% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  46% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  48% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  50% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  52% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  54% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  56% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  58% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  60% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  62% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  64% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  66% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  68% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  70% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  72% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  74% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  76% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  78% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  80% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  82% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  84% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  86% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  88% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  90% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  92% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  94% {
    transform: translate(2px, 2px) rotate(0.5deg); }
  96% {
    transform: translate(0px, 2px) rotate(0.5deg); }
  98% {
    transform: translate(2px, 0px) rotate(0.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); } }
@keyframes shake-chunk {
  2% {
    transform: translate(-12px, -13px) rotate(-8deg); }
  4% {
    transform: translate(9px, -1px) rotate(-14deg); }
  6% {
    transform: translate(3px, -8px) rotate(-13deg); }
  8% {
    transform: translate(14px, 13px) rotate(13deg); }
  10% {
    transform: translate(6px, 3px) rotate(13deg); }
  12% {
    transform: translate(-11px, -6px) rotate(4deg); }
  14% {
    transform: translate(-10px, -5px) rotate(3deg); }
  16% {
    transform: translate(10px, 10px) rotate(14deg); }
  18% {
    transform: translate(-3px, 12px) rotate(-3deg); }
  20% {
    transform: translate(12px, -1px) rotate(-7deg); }
  22% {
    transform: translate(6px, -7px) rotate(-7deg); }
  24% {
    transform: translate(4px, -4px) rotate(-5deg); }
  26% {
    transform: translate(-14px, 7px) rotate(-12deg); }
  28% {
    transform: translate(-3px, 5px) rotate(-2deg); }
  30% {
    transform: translate(-3px, 8px) rotate(-4deg); }
  32% {
    transform: translate(-13px, -7px) rotate(-2deg); }
  34% {
    transform: translate(-14px, 1px) rotate(8deg); }
  36% {
    transform: translate(0px, 10px) rotate(6deg); }
  38% {
    transform: translate(-9px, -1px) rotate(8deg); }
  0%, 40%, 100% {
    transform: translate(0, 0) rotate(0); } 
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes candyUp {
  0%, 30% {
    transform: translate3d(0, -10rem, 0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes progress {
  0% {
    background-position-x: tovw(-15);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-3-28 18:39:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
 @-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-3-31 15:25:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-out-left
 * ----------------------------------------
 */
 @-webkit-keyframes roll-out-left {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(tovw(-250)) rotate(-720deg);
            transform: translateX(tovw(-250)) rotate(-720deg);
    opacity: 0;
  }
}
@keyframes roll-out-left {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(tovw(-250)) rotate(-720deg);
            transform: translateX(tovw(-250)) rotate(-720deg);
    opacity: 0;
  }
}

@keyframes rotateHorizon {
  0%, 50%, 100% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(-20deg);
  }
  75% {
    transform: rotateY(20deg);
  }
}
@keyframes fadingInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-4-21 11:12:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-in-2-cw
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-in-2-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-4-21 11:13:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
}
@keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
}

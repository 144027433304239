.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: url(/images/btn.png)  no-repeat !important;
  background-size: 100% 100% !important;
  font-size: 1vw;
  text-transform: uppercase;
  color: #fff !important;
  height: tovw(58);
  line-height: tovw(58);
  min-width: tovw(222);
  padding: 0 tovw(10);
  outline: none !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0;
  position: relative;
  font-weight: normal;
  transition: transform .25s ease;
  font-family: $font-fifa;
  text-shadow: 0 0 .1vw #ff15ec, 0 0 .1vw #ff15ec, 0 0 .5vw #ff15eba4, 0 0 1vw #ff15eba4, 0 0 1vw #ff15ebd3;
  filter: drop-shadow(0 .5vw .5vw rgba(0, 0, 0, .8));
  &--fw {
    width: 100%;
    min-width: initial;
  }
  &--transparent {
    background: transparent !important;
    border: 1px solid #fff !important;
    font-size: tovw(20);
    font-weight: normal;
    border-radius: .3vw !important;
    height: tovw(48);
    line-height: tovw(48);
    min-width: tovw(150);
    text-shadow: none !important;
    img {
      vertical-align: middle;
      width: tovw(15);
      transform: translateY(-18%);
    }
    &--gray {
      color: #713ac4 !important;
      background: rgba(86, 8, 159, .5) !important;
      border-color: #9e01ff !important;
    }
    em {
      font-style: normal;
      display: inline-block;
      vertical-align: middle;
    }
  }
  span {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &--small {
    min-width: tovw(160);
    height: tovw(50);
    line-height: tovw(50);
    font-size: tovw(22);
  }
  &--long {
    min-width: 12vw;
  }
  &--light {
    background: url(/images/btn-light.png)  no-repeat !important;
    background-size: 100% 100% !important;
    font-size: 0.9vw;
  }
  &--pink {
    background: url(/images/btn-pink.png)  no-repeat !important;
    background-size: 100% 100% !important;
  }
  &--dark {
    background: url(/images/btn-dark.png)  no-repeat !important;
    background-size: 100% 100% !important;
  }
  &--darken {
    filter: brightness(60%);
  }
  &--slide {
    position: absolute;
    top: tovw(440);
    right: 4.604vw;
    transform: rotate(-90deg) !important;
    transform-origin: right;
    animation: ping-horizon 2s infinite;
    animation-delay: .5s;
    &:after {
      content: "";
      position: absolute;
      width: tovw(37);
      height: tovw(21);
      top: 115%;
      left: 50%;
      transform: translateX(-50%);
      background: url(/images/arrow.png)  no-repeat !important;
      background-size: 100% 100%  !important;
    }
    &--reverse {
      &:after {
        top: auto;
        bottom: 115%;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }
  &--choose {
    min-width: tovw(220);
    margin-bottom: .5vw;
  }
  @include min-screen(1200px) {
    &:hover {
      transform: translateY(-.5rem);
    }
  }
  &--no-hover {
    cursor: default;
    &:hover {
      transform: none;
    }
  }
}

.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  height: tovw(52);
  line-height: tovw(52);
  min-width: tovw(180);
  font-size: tovw(20);
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  @extend %grayscale;
}
html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  font-size: tovw(18);
  color: #fff;
  font-family: $roboto;
  background-color: #e6e5e2;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  display: inline-block;
  max-width: 100% !important;
}
#root {
  position: relative;
  min-height: 100%;
}
button {
  outline: none !important;
}
a {
  color: #fff;
  transition: color .25s ease;
  outline: none !important;
  &:hover {
    color: $orange;
    text-decoration: none;
  }
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: tovw(8);
  padding-right: tovw(8);
}
.container {
  max-width: tovw(1400) !important;
  padding-left: tovw(8);
  padding-right: tovw(8);
  #header & {
    max-width: 100% !important;
  }
  &--content {
    max-width: tovw(1280) !important;
  }
}
.row {
  margin-left: tovw(-8);
  margin-right: tovw(-8);
}
.swal2-shown,
.ReactModal__Body--open {
	#root {
		//filter: blur(5px);
	}
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0,0,0,.8) !important;
  overflow-y: auto !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  z-index: 99999 !important;
  .swal2-popup {
		height: auto !important;
    min-width: tovw(680) !important;
    min-height: tovw(333) !important;		
    background: url(/images/popup-small.png) no-repeat center !important;
    background-size: 100% 100% !important;
    padding-top: 2rem;
    padding-bottom: 4rem;
    border-radius: 0 !important;
    .swal2-close,
    .close {
      position: absolute;
      width: 14px;
      height: 14px;
      background: url(/images/close.png) no-repeat center;
      background-size: 100%;
      top: -14px;
      right: -14px;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #fff !important;
      font-family: serif;
      font-weight: 300;
      z-index: 9;
      text-indent: -9999px;
    }
    #swal2-content {
      font-size: tovw(20);
      color: #fff;
      line-height: 150%;
      text-transform: uppercase;
      padding-left: 3%;
      padding-right: 3%;
      font-family: $font-fifa;
      p {
        font-size: tovw(20);
        color: #fff;
        line-height: 150%;
        text-transform: uppercase;
        font-family: $font-fifa;
      }
    }
    #swal2-title,
    h2 {
      font-size: tovw(36);
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      color: #fff !important;
      justify-content: center;
      margin-bottom: 0;
      font-family: $font-heading;
      letter-spacing: 1px;
      background: -webkit-linear-gradient(-86deg, #5af7dc 5%, #ff16ff 53%, #ffb6ff 91%);
      -webkit-background-clip: text;
      -webkit-text-stroke: 3px transparent;
      font-style: italic;
      margin-bottom: 1vw;
    }
    &.popup-image {
      background: transparent !important;
      box-shadow: none !important;
      width: auto !important;
      padding: 0 !important;
      border-radius: .5vw !important;
      //overflow: hidden;
      #swal2-content {
        padding-left: 0;
        padding-right: 0;
      }
      img {
        max-width: 60vw !important;
        margin-left: auto;
        margin-right: auto;
        display: block;
        backface-visibility: hidden;
      }
    }
    &.popup-single-reward {
      background: url(/images/popup-reward.png) no-repeat center !important;
      background-size: 100% 100% !important;
      min-width: tovw(510) !important;
      min-height: tovw(584) !important;	
    }
    &.popup-single-line {
      background: url(/images/popup-reward-line.png) no-repeat center !important;
      background-size: 100% 100% !important;
      min-width: tovw(510) !important;
      min-height: tovw(584) !important;	
    }
    &.popup-rewards {
      &--big {
        min-width: tovw(1047) !important;
      }
    }
  }
}
.single-reward {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: tovw(208);
  margin-bottom: 1vw;
}
.ReactModal__Overlay {
  display: flex!important;
  position: fixed!important;
  top: 0!important;
  right: 0!important;
  bottom: 0;
  left: 0!important;
  flex-direction: row!important;
  align-items: center!important;
  justify-content: center!important;
  padding: 10px!important;
  background: rgba(0, 0, 0, .8) !important;
  z-index: 99999 !important;
  overflow-x: hidden!important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  width: tovw(816) !important;
  min-height: tovw(489) !important;
  background: url(/images/popup-bg.png) no-repeat center !important;
  background-size: 100% 100% !important;
  border: 0 !important;
  padding: 2rem 0 !important;
  padding-bottom: 4rem !important;
  text-align: center;
  outline: none;
  .modal-description {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
  }
  &.modal-player {
    width: tovw(1280) !important;
  }
  &.modal-rule {
    width: tovw(1105) !important;
    min-height: tovw(700) !important;
  }
  &.modal-history {
    width: 60% !important;
    padding-bottom: 5vw !important;
  }
  &.modal-prize {
    min-width: tovw(1105);
    min-height: tovw(1164);
    background: url(/images/popup-gift.png) no-repeat center !important;
    background-size: 100% 100% !important;
    flex-direction: row;
    flex-wrap: wrap;
    h2 {
      width: tovw(1026);
      padding: 0;
      margin-bottom: 1vw;
    }
    .modal-description {
      width: tovw(1026);
      margin-left: auto;
      margin-right: auto;
    }
  }
  .close {
    position: absolute;
    width: 14px;
    height: 14px;
    background: url(#{$cdnUrl}/images/close.png) no-repeat center;
    background-size: 100%;
    top: -14px;
    right: -14px;
    opacity: 1;
    font-size: 4rem;
    outline: none !important;
    color: #fff !important;
    font-family: serif;
    font-weight: 300;
    z-index: 9;
    text-indent: -9999px;
  }
  h2 {
    font-size: tovw(36);
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff !important;
    justify-content: center;
    margin-bottom: 0;
    font-family: $font-heading;
    letter-spacing: 1px;
    background: -webkit-linear-gradient(-86deg, #5af7dc 5%, #ff16ff 53%, #ffb6ff 91%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
    font-style: italic;
    margin-bottom: 1vw;
  }
	p,
	li {
    font-size: tovw(20);
    color: #fff;
    line-height: 150%;
    font-family: $font-fifa;
  }
}
#main-body {
  position: relative;
  min-height: 100vh;  
  background: url(/images/page-bg.jpg) no-repeat top center;
  background-size: cover;
}
.main-content {
  position: relative;
  padding-top: tovw(73);
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: tovw(10) !important;
  right: tovw(-35) !important;
  z-index: 1;
  background: transparent !important;
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #fff;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
  .modal-history & {
    right: 0 !important;
    &:before {
      background: #fff;
    }
  }
  .board-top & {
    &:before {
      background: #fff;
    }
  }
}
.ScrollbarsCustom-Thumb {
  width: 100% !important;
  border-radius: .5vw !important;
  background: #fff !important;
  .modal-history & {
    background: #fff !important;
  }
  .board-top & {
    background: #fff !important;
  }
}
#header {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(221,0,255);
  background: -moz-linear-gradient(270deg, rgba(221,0,255,1) 0%, rgba(97,0,255,1) 100%);
  background: -webkit-linear-gradient(270deg, rgba(221,0,255,1) 0%, rgba(97,0,255,1) 100%);
  background: linear-gradient(270deg, rgba(221,0,255,1) 0%, rgba(97,0,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dd00ff",endColorstr="#6100ff",GradientType=1);
  border-bottom: 1px solid #fff;
}
#footer {
  position: relative;
  background: #000;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  .container {
    width: tovw(1320);
  }
  .footer-logo {
    display: inline-block;
    width: tovw(226);
  }
  .age {
    display: inline-block;
    width: tovw(142);
  }
  .col:nth-child(2) {
    flex: 0 0 tovw(910);
    max-width: tovw(910);
  }
  p {
    font-size: tovw(11);
    text-transform: uppercase;
    color: #6a6a6a;
    text-align: justify;
    margin-bottom: 0;
  }
}
.has-title {
  position: relative;
  .title {
    padding: .1vw .3vw;
    background: rgba(0, 0, 0, .9);
    white-space: nowrap;
    font-size: tovw(16);
    color: #fff;
    font-weight: 500;
    text-align: center;
    position: absolute;
    bottom: 102%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity .25s ease;
    line-height: 150%;
    font-style: normal;
    border-radius: .3vw;
    font-weight: 500;
    font-family: $font-heading;
    &--normal {
      text-transform: none;
    }
  }
  &:hover {
    z-index: 4 !important;
    .title {
      opacity: 1;
    }
  }
}
.main-container {
  width: tovw(1400);
  margin-left: auto;
  margin-right: auto;
}
.menu-link {
  height: tovw(72);
  line-height: tovw(72);
  display: inline-block;
  vertical-align: middle;
  margin: 0 tovw(20);
  transition: transform .25s ease;
  position: relative;
  font-family: $robotoCon;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff !important;
  img {
    height: 100%;
    display: block;
    max-width: initial !important;
  }
  &--logo {
    height: tovw(50);
    margin-bottom: 0;
    margin-top: tovw(12);
    transform: none !important;
    float: left;
  }
  @include min-screen(1200px) {
    &:hover {
      transform: translateY(-.2vw);
    }
  }
  &--login {
    position: absolute;
    width: tovw(232);
    height: tovw(72);
    position: absolute;
    top: 0;
    right: 0;
    background: url(/images/login-bg.png) no-repeat;
    background-size: 100% 100%;
    margin: 0;
    &:hover {
      transform: none;
      span {
        transform: translateY(-55%);
      }
    }
    span {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      left: 0;
      font-size: tovw(19);
      transform: translateY(-50%);
      padding-left: 2vw;
      transition: transform .25s ease;
      strong {
        display: inline-block;
        vertical-align: middle;
        max-width: tovw(185);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        display: inline-block;
        width: tovw(32);
        vertical-align: middle;
      }
    }
  }
}
.col {
  &--action {
    flex: 0 0 tovw(550);
    max-width: tovw(550);
    z-index: 3;
    p {
      margin-bottom: 0;
    }
  }
  &--board {
    flex: 0 0 tovw(850);
    max-width: tovw(850);
  }
}
.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  pointer-events: none;
  display: none;
}
.round-rewards {
  margin-left: auto;
  margin-right: auto;
  width: tovw(342);
  height: tovw(342);
  position: relative;
  > div {
    position: absolute;
    white-space: nowrap;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:before {
    content: "";
    background: url(/images/ball.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: rotation 60s infinite linear;
  }
  &--2 {
    .round-item {
      margin-left: .5vw;
      margin-right: .5vw;
    }
  }
  &--3 {
    .round-item {
      &--1 {
        transform: translateX(3vw) scale(0.8);
      }
      &--2 {
        z-index: 1;
      }
      &--3 {
        transform: translateX(-3vw) scale(0.8);
      }
    }
  }
  &--4 {
    .round-item {
      &--1 {
        transform: scale(0.8);
        margin-right: -3vw;
      }
      &--2 {
        z-index: 2;
      }
      &--3 {
        z-index: 1;
        margin-left: -3vw;
      }
      &--4 {
        transform: scale(0.8);
        margin-left: -3vw;
      }
    }
  }
  &__title {
    text-transform: uppercase;
    font-family: $font-fifa;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: tovw(24);
    bottom: 3%;
  }
  &__title-random {
    text-transform: uppercase;
    font-family: $font-fifa;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    right: 0%;
    top: 50%;
    transform: translate(75%, -55%) rotate(90deg);
    white-space: nowrap;
    font-size: tovw(16);
  }
}
.round-item {
  display: inline-block;
  width: tovw(141);
  position: relative;
  img {
    animation: floating 5s infinite linear;
    filter: drop-shadow(0 0 1vw #ff15eb86);
  }
}
.text-neon {
  text-shadow: 0 0 .2vw #ff15eb49, 0 0 .2vw #ff15eb48, 0 0 .2vw #ff15eb4f, 0 0 .3vw #ff15eb86, 0 0 .5vw #ff15eb86, 0 0 1vw #ff15eb86, 0 0 1.5vw #ff15eb86;
}
.checkbox {
  display: inline-block;
  position: relative;
  font-family: $font-fifa;
  font-size: tovw(26);
  margin-right: 1vw;
  color: #fff !important;
  img {
    width: tovw(37);
    height: tovw(37);
    vertical-align: middle;
    margin-right: .2vw;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
}
.balance {
  .col-6:nth-child(2) {
    padding-top: .5vw;
  }
}
.btns {
  width: 24vw;
  margin-left: auto;
  margin-right: auto;
  font-family: $robotoCon;
  font-size: tovw(20);
  .col-6 {
    margin-bottom: .8vw;
  }
  &--darken {
    filter: brightness(60%);
  }
}
.main-home {
  padding-top: tovw(100);
  padding-bottom: tovw(50);
  overflow: hidden;
}
.title-main {
  position: relative;
  text-align: center;
  img {
    width: tovw(458);
  }
}
.reset-fc {
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-.15vw);
  img {
    width: tovw(16);
  }

}
.round-btns {
  margin-bottom: tovw(38);
  a {
    margin-left: .5vw;
  }
}
.col--prize {
  flex: 0 0 10%;
  max-width: 10%;
  &--select {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
.cover {
  display: inline-block;
  position: relative;
  width: tovw(147);
  height: tovw(147);
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(/images/cover.png) no-repeat;
    background-size: 100% 100%;
  }
  &__img {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    perspective: 200px;
    z-index: 2;
    img {
      width: 100%;
      animation: rotateHorizon 5s infinite linear;
    }
  }
  &--line {
    &:before {
      background: url(/images/cover-line.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  &--accumulate {
    &:before {
      background: url(/images/cover-accumulate.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  &--special {
    &:before {
      background: url(/images/cover-special.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  &--x2 {
    &:before {
      background: url(/images/cover-x2.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  &--select {
    width: tovw(241);
    height: tovw(92);
    padding-top: .4vw;
    em {
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      z-index: 1;
      font-style: normal;
      margin-left: .5vw;
      margin-right: .5vw;
      text-transform: uppercase;
      font-size: tovw(12);
    }
    &:before {
      background: url(/images/cover-select.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  &__img-select {
    display: inline-block;
    height: tovw(75);
    vertical-align: middle;
    img {
      height: 100%;
      animation: rotateHorizon 5s infinite linear;
    }
  }
  &--big {
    width: tovw(175);
    height: tovw(175);
    margin-left: 2vw;
    margin-right: 2vw;
    vertical-align: middle;
  }
  &--small {
    width: tovw(90);
    height: tovw(90);
  }
  &--blur {
    &:before {
      background: url(/images/cover-blur.png) no-repeat;
      background-size: 100% 100%;
    }
    &--inactive {
      img {
        opacity: 0;
      }
    }
    &--active {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url(/images/cover-special.png) no-repeat;
        background-size: 100% 100%;
        opacity: .5;
        animation: fadingInOut .3s linear both;
      }
      img {
        opacity: 1;
      }
    }
  }
  &__winning {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(/images/cover-winning.png) no-repeat !important;
    background-size: 100% 100% !important;
  }
  &__claimed {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(/images/cover-claimed.png) no-repeat !important;
    background-size: 100% 100% !important;
    text-align: center;
    z-index: 2;
    span {
      position: absolute;
      width: 40%;
      left: 30%;
      top: 55%;
      transform: translateY(-50%);
      font-size: tovw(22);
      color: #df7eff;
      text-transform: uppercase;
      font-family: $font-fifa;
      line-height: 110%;
    }
  }
  &__x2 {
    font-size: tovw(20);
    color: #fff;
    position: absolute;
    top: .2vw;
    left: 50%;
    transform: translateX(-50%);
    // background: rgba(0, 0, 0, .6);
    // text-shadow: 1px 1px 1px #000;
    padding: 0 .5vw;
    border-radius: .2vw;
    z-index: 2;
    font-weight: bold;
    font-family: $font-fifa;
    @extend .text-neon;
  }
}
.col--winning {
  z-index: 3;
}
.board-spin {
  position: relative;
  min-height: 42.5vw;
  width: 97.5%;
  margin-left: auto;
  &--darken {
    .item-pool {
      filter: brightness(60%);
    }
  }
  &__pool {
    position: absolute;
    width: tovw(650);
    min-height: tovw(632);
    background: rgba(90, 35, 181, .5);
    border-radius: .8vw;
    padding: tovw(16);
    padding-bottom: .3vw;
    text-align: center;
    bottom: 0;
    right: 0;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: tovw(10);
      border-radius: tovw(5);
      background: #ff8cf4;
      z-index: 8;
      filter: drop-shadow(0 0 .3vw #ff8cf4);
      border: 1px solid #f3cdff;
      transition: width .6s linear;
      pointer-events: none;
      opacity: 0;
    }
    &--line-1 {
      &:after {
        top: 85.5%;
        left: 36%;
        transform: translate(-50%);
      }
    }
    &--line-2 {
      &:after {
        top: 61%;
        left: 36%;
        transform: translate(-50%);
      }
    }
    &--line-3 {
      &:after {
        top: 38%;
        left: 36%;
        transform: translate(-50%);
      }
    }
    &--line-4 {
      &:after {
        top: 13.5%;
        left: 36%;
        transform: translate(-50%);
      }
    }
    &--line-5 {
      &:after {
        top: 35.5%;
        left: 36%;
        transform: translate(-50%) rotate(45deg);
      }
      &.active-line {
        &:after {
          width: 50vw !important;
        }
      }
    }
    &--line-6 {
      &:after {
        top: 35%;
        left: 14%;
        transform: translate(-50%) rotate(90deg);
      }
    }
    &--line-7 {
      &:after {
        top: 35%;
        left: 38%;
        transform: translate(-50%) rotate(90deg);
      }
    }
    &--line-8 {
      &:after {
        top: 35%;
        left: 63%;
        transform: translate(-50%) rotate(90deg);
      }
    }
    &--line-9 {
      &:after {
        top: 35%;
        left: 87%;
        transform: translate(-50%) rotate(90deg);
      }
    }
    &.active-line {
      &:after {
        width: 36vw;
        opacity: 1;
      }
    }
  }
}
.item-special {
  position: absolute;
  &--1,
  &--2,
  &--3,
  &--4,
  &--5 {
    left: 0;
  }
  &--5 {
    top: 1%;
  }
  &--6,
  &--7,
  &--8,
  &--9,
  &--10 {
    top: 1%;
  }
  &--1 {
    bottom: 1.7%;
  }
  &--2 {
    bottom: 20.7%;
  }
  &--3 {
    bottom: 39.7%;
  }
  &--4 {
    bottom: 58.7%;
  }
  &--6 {
    left: 22%;
  }
  &--7 {
    left: 41.7%;
  }
  &--8 {
    left: 61.2%;
  }
  &--9 {
    left: 80.7%;
  }
  &--10 {
    left: 100.2%;
  }
  &--active {
    &:before {
      background: url(/images/cover-special.png) no-repeat;
      background-size: 100% 100%;
      opacity: 1 !important;
      animation: none;
    }
    img {
      opacity: 1 !important;
      animation: heartBeat 2s infinite !important;
    }
  }
  .round-rewards__title-random {
    transform: translate(60%, -55%) rotate(90deg);
  }
}
.main-spin {
  transform: translateX(-20%);
  left: 0 !important;
}
.main-accumulate {
  transform: translateX(-20%);
}
.main-section {
  position: absolute;
  top: 5vw;
  left: 50%;
  opacity: 0;
  transition: transform .25s ease, opacity .25s ease;
  pointer-events: none;
  &--active {
    opacity: 1;
    pointer-events: all;
    &.main-spin {
      position: relative;
      transform: translateX(0);
      left: auto;
      top: auto;
    }
    &.main-accumulate {
      transform: translateX(-50%);
    }
  }
}
.btn-slide {
  position: absolute;
}
.gift-list {
  width: 62vw;
  margin-left: auto;
  margin-right: auto;
}
.col {
  &--gift {
    flex: 0 0 16%;
    max-width: 16%;
    &--select {
      flex: 0 0 24%;
      max-width: 24%;
    }
  }
}
.item-gift {
  text-align: center;
  position: relative;
  margin-bottom: tovw(60);
  &__img {
    height: 6vw;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    margin-bottom: .5vw;
    img {      
      height: 100%;
    }
    &--select {
      margin-left: .4vw;
      margin-right: .4vw;
    }
  }
  p {
    text-transform: uppercase;
    position: absolute;
    bottom: 96%;
  }
  .title {
    z-index: 2;
  }
}
.active-accumulate {
  position: relative;
  min-height: 118vh;
}
.col--reward {
  flex: 0 0 tovw(122);
  max-width: tovw(122);
  margin-bottom: .5vw;
  .cover {
    width: tovw(114);
    height: tovw(114);
  }
}
.item-selection {
  position: relative;
  display: inline-block;
  &__img {
    height: tovw(296);
    margin-bottom: .5vw;
    img {
      height: 100%;
      filter: drop-shadow(0 0 .5vw #ff15ec);
    }
  }
}
.mb0 {
  margin-bottom: 0 !important;
}
.tu {
  text-transform: uppercase;
}
.darken {
  img {
    filter: brightness(60%);
  }
}
.col--effect {
  animation: rotate-in-2-cw .8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  &--line {
    animation: swirl-in-fwd 0.8s ease-out both;
    animation-delay: .9s;
  }
}
.sidebar-btns {
  position: absolute;
  top: 25vw;
  width: tovw(94);
  left: 1vw;
  a {
    display: block;
    margin-bottom: 1.5vw;
    transition: transform .25s ease;
    &:hover {
      transform: translateY(-1rem);
    }
  }
}
.modal-description {
  h4 {
    margin-bottom: .5vw;
  }
}
.table-history {
  width: 100%;
  tr {
    th,
    td {
      font-size: tovw(20);
      color: #fff;
      padding: .5vw;
    }
    th {
      padding: .5vw;
      font-weight: bold;
      font-family: $robotoCon;
      text-transform: uppercase;
      border-bottom: 1px solid #fff;
    }
  }
}
.btn-playnow {
  position: absolute;
  bottom: 5vw;
  left: 50%;
  transform: translateX(-50%);
  > div {
    animation: ping 2s infinite;
  }
}
.item-gift,
.item-selection {
  .cover__claimed {
    width: 6vw;
    height: 6vw;
    background: url(/images/claimed.png) no-repeat !important;
    background-size: auto;
    background-size: 100% 100% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.btn-sidebar {
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
  span {
    position: absolute;
    bottom: 14.5%;
    left: 0;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 .3vw #ff15ec, 0 0 .5vw #ff15ec, 0 0 1vw #ff15ec, 0 0 1.2vw #ff15ec;
    color: #fff !important;
    font-family: $font-fifa;
    font-size: tovw(22);
    line-height: 100%;
  }
}
.first-tag {
  position: absolute;
  font-size: .55vw;
  white-space: nowrap;
  left: 50%;
  bottom: 105%;
  text-transform: uppercase;
  transform: translateX(-50%);
}
.input-notice {
  position: absolute;
  width: 1vw;
  height: 1vw;
  font-size: .8vw;
  text-align: center;
  line-height: 1vw;
  background: #ff15ec;
  border: 1px solid #fff;
  border-radius: 50%;
  font-style: normal;
  text-transform: none;
  top: .45vw;
  right: -1.5vw;
  .play-balance & {
    width: 1.2vw;
    height: 1.2vw;
  }
}
.is-spinning {
  a {
    pointer-events: none;
  }
}
.title-accumulate {
  width: tovw(481);
  margin-left: auto;
  margin-right: auto;
}
.btn-ping {
  animation: ping 2s infinite;
  animation-delay: .2s;
  &.btn-sidebar {
    animation-delay: 0s;
  }
}
.result-title {
  position: absolute;
  top: 100%;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  font-family: $font-fifa;
  font-weight: bold;
}
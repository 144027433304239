/// Helpers (helper classes & placeholders)

/// Placeholders
%clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

/// Display helpers
.clearfix {
  @extend %clearfix;
}
.float-left  { float: left; }
.float-right { float: right; }

.float-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.display-inline       { display: inline; }
.display-block        { display: block; }
.display-inline-block { display: inline-block; }
.display-none         { display: none; }

// Hide element while making it readable for screen readers
// Shamelessly borrowed from HTML5Boilerplate:
// https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.margin-top-none  { margin-top: 0; }
.margin-top-half  { margin-top: 0.5rem; }
.margin-top-one   { margin-top: 1rem; }
.margin-top-two   { margin-top: 2rem; }

.margin-bottom-none  { margin-bottom: 0; }
.margin-bottom-half  { margin-bottom: 0.5rem; }
.margin-bottom-one   { margin-bottom: 1rem; }
.margin-bottom-two   { margin-bottom: 2rem; }


/// Text helpers
.text-color   { color: $color-primary; }

.text-left    { text-align: left; }
.text-right   { text-align: right; }
.text-center  { text-align: center; }
.text-justify { text-align: justify; }

.align-top    { vertical-align: top; }
.align-bottom { vertical-align: bottom; }
.align-middle { vertical-align: middle; }

// Hide text while making it readable for screen readers
// 1. Needed in WebKit-based browsers because of an implementation bug;
//    See: https://code.google.com/p/chromium/issues/detail?id=457146
.text-hide {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}
%grayscale,
.grayscale {
    filter: gray !important; /* IE6-9 */
    -webkit-filter: grayscale(1) !important; /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1) !important; /* Microsoft Edge and Firefox 35+ */
    transition: filter .25s ease;
}
%none-grayscale,
.none-grayscale {
  filter: none !important;
  -webkit-filter: grayscale(0%) !important;
}
.rating {
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: text-top;
  margin-top: -5px;
  label {
    margin-bottom: 0;
  }
}

.rating > input { display: none; }
.rating > label:before {
  margin: 7px;
  font-size: 10px;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
  margin-left: -3px;
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
 float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label { color: $yellow;  } /* hover previous stars in list */

@keyframes scrolldown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.delay-1 {
  animation-delay: 0.25s;
}
.delay-2 {
  animation-delay: 0.4s;
}
.delay-3 {
  animation-delay: 0.55s;
}
.delay-4 {
  animation-delay: 0.7s;
}
.delay-5 {
  animation-delay: 0.85s;
}
.delay-6 {
  animation-delay: 1s;
}
.delay-7 {
  animation-delay: 1.15s;
}
.delay-8 {
  animation-delay: 1.3s;
}
.delay-9 {
  animation-delay: 1.45s;
}
.delay-10 {
  animation-delay: 1.6s;
}
.delay-11 {
  animation-delay: 1.75s;
}
%hidden,
.hidden {
  opacity: 0;
  visibility: hidden;
}
%show,
.show {
  opacity: 1;
  visibility: visible;
}.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner-dot {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.spinner-dot > div {
  width: 18px;
  height: 18px;
  background-color: #fff;
  opacity: .6;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-dot .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-dot .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/* Horizontal */
@keyframes glitch-anim-1-horizontal {
	0% { 
		-webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
		clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
	}
	10% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
		clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
	}
	20% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
		clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
	}
	30% {
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
		clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
	}
	40% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	50% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	60% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	70% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	80% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	90% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	100% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
}

@keyframes glitch-anim-2-horizontal {
	0% { 
		-webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
		clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
	}
	15% {
		-webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
		clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
	}
	22% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
		clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
	}
	31% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	45% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
		clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
	}
	51% {
		-webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
		clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
	}
	63% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	76% {
		-webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
		clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
	}
	81% {
		-webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
		clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
	}
	94% {
		-webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
		clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
	}
	100% {
		-webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
		clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
	}
}

@keyframes glitch-anim-3-horizontal {
	0% { 
		-webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
		clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
	}
	5% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
		clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
	}
	10% {
		-webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
		clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
	}
	25% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
		clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
	}
	27% {
		-webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
		clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
	}
	30% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
		clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
	}
	33% {
		-webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
		clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
	}
	37% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
		clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
	}
	40% {
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	45% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
		clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
	}
	50% {
		-webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
		clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
	}
	53% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
		clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
	}
	57% {
		-webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
		clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
	}
	60% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
		clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
	}
	65% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
		clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
	}
	70% {
		-webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
		clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
	}
	73% {
		-webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
		clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
	}
	80% {
		-webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
		clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
	}
	100% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
		clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
	}
}

/* Vertical */
@keyframes glitch-anim-1-vertical {
	0% { 
		-webkit-clip-path: polygon(2% 0, 5% 0, 5% 100%, 2% 100%);
		clip-path: polygon(2% 0, 5% 0, 5% 100%, 2% 100%);
	}
	10% {
		-webkit-clip-path: polygon(15% 0, 15% 0, 15% 100%, 15% 100%);
		clip-path: polygon(15% 0, 15% 0, 15% 100%, 15% 100%);
	}
	20% {
		-webkit-clip-path: polygon(10% 0, 20% 0, 20% 100%, 10% 100%);
		clip-path: polygon(10% 0, 20% 0, 20% 100%, 10% 100%);
	}
	30% {
		-webkit-clip-path: polygon(1% 0, 2% 0, 2% 100%, 1% 100%);
		clip-path: polygon(1% 0, 2% 0, 2% 100%, 1% 100%);
	}
	40% {
		-webkit-clip-path: polygon(33% 0, 33% 0, 33% 100%, 33% 100%);
		clip-path: polygon(33% 0, 33% 0, 33% 100%, 33% 100%);
	}
	50% {
		-webkit-clip-path: polygon(44% 0, 44% 0, 44% 100%, 44% 100%);
		clip-path: polygon(44% 0, 44% 0, 44% 100%, 44% 100%);
	}
	60% {
		-webkit-clip-path: polygon(50% 0, 20% 0, 20% 100%, 50% 100%);
		clip-path: polygon(50% 0, 20% 0, 20% 100%, 50% 100%);
	}
	70% {
		-webkit-clip-path: polygon(70% 0, 70% 0, 70% 100% 70%, 70% 100%);
		clip-path: polygon(70% 0, 70% 0, 70% 100% 70%, 70% 100%);
	}
	80% {
		-webkit-clip-path: polygon(80% 0, 80% 0, 80% 100% 80%, 80% 100%);
		clip-path: polygon(80% 0, 80% 0, 80% 100% 80%, 80% 100%);
	}
	90% {
		-webkit-clip-path: polygon(50% 0, 55% 0, 55% 100%, 50% 100%);
		clip-path: polygon(50% 0, 55% 0, 55% 100%, 50% 100%);
	}
	100% {
		-webkit-clip-path: polygon(70% 0, 80% 0, 80% 100%, 70% 100%);
		clip-path: polygon(70% 0, 80% 0, 80% 100%, 70% 100%);
	}
}

@keyframes glitch-anim-2-vertical {
	0% { 
		-webkit-clip-path: polygon(25% 0, 30% 0, 30% 100%, 25% 100%);
		clip-path: polygon(25% 0, 30% 0, 30% 100%, 25% 100%);
	}
	15% {
		-webkit-clip-path: polygon(3% 0, 3% 0, 3% 100%, 3% 100%);
		clip-path: polygon(3% 0, 3% 0, 3% 100%, 3% 100%);
	}
	22% {
		-webkit-clip-path: polygon(5% 0, 20% 0, 20% 100%, 5% 100%);
		clip-path: polygon(5% 0, 20% 0, 20% 100%, 5% 100%);
	}
	31% {
		-webkit-clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
		clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
	}
	45% {
		-webkit-clip-path: polygon(40% 0, 40% 0, 40% 100%, 40% 100%);
		clip-path: polygon(40% 0, 40% 0, 40% 100%, 40% 100%);
	}
	51% {
		-webkit-clip-path: polygon(52% 0, 59% 0, 59% 100%, 52% 100%);
		clip-path: polygon(52% 0, 59% 0, 59% 100%, 52% 100%);
	}
	63% {
		-webkit-clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
		clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
	}
	76% {
		-webkit-clip-path: polygon(75% 0, 75% 0, 75% 100%, 75% 100%);
		clip-path: polygon(75% 0, 75% 0, 75% 100%, 75% 100%);
	}
	81% {
		-webkit-clip-path: polygon(65% 0, 40% 0, 40% 100%, 65% 100%);
		clip-path: polygon(65% 0, 40% 0, 40% 100%, 65% 100%);
	}
	94% {
		-webkit-clip-path: polygon(45% 0, 50% 0, 50% 100%, 45% 100%);
		clip-path: polygon(45% 0, 50% 0, 50% 100%, 45% 100%);
	}
	100% {
		-webkit-clip-path: polygon(14% 0, 33% 0, 33% 100%, 14% 100%);
		clip-path: polygon(14% 0, 33% 0, 33% 100%, 14% 100%);
	}
}

@keyframes glitch-anim-3-vertical {
	0% { 
		-webkit-clip-path: polygon(1% 0, 3% 0, 3% 100%, 1% 100%);
		clip-path: polygon(1% 0, 3% 0, 3% 100%, 1% 100%);
	}
	5% {
		-webkit-clip-path: polygon(10% 0, 9% 0, 9% 100%, 10% 100%);
		clip-path: polygon(10% 0, 9% 0, 9% 100%, 10% 100%);
	}
	10% {
		-webkit-clip-path: polygon(5% 0, 6% 0 6% 100%, 5% 100%);
		clip-path: polygon(5% 0, 6% 0 6% 100%, 5% 100%);
	}
	25% {
		-webkit-clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
		clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
	}
	27% {
		-webkit-clip-path: polygon(10% 0, 10% 0, 10% 100%, 10% 100%);
		clip-path: polygon(10% 0, 10% 0, 10% 100%, 10% 100%);
	}
	30% {
		-webkit-clip-path: polygon(30% 0, 25% 0, 25% 100%, 30% 100%);
		clip-path: polygon(30% 0, 25% 0, 25% 100%, 30% 100%);
	}
	33% {
		-webkit-clip-path: polygon(15% 0, 16% 0, 16% 100%, 15% 100%);;
		clip-path: polygon(15% 0, 16% 0, 16% 100%, 15% 100%);
	}
	37% {
		-webkit-clip-path: polygon(40% 0, 39% 0, 39% 100%, 40% 100%);;
		clip-path: polygon(40% 0, 39% 0, 39% 100%, 40% 100%);
	}
	40% {
		-webkit-clip-path: polygon(20% 0, 21% 0, 21% 100%, 20% 100%);
		clip-path: polygon(20% 0, 21% 0, 21% 100%, 20% 100%); 
	}
	45% {
		-webkit-clip-path: polygon(60% 0, 55% 0, 55% 100%, 60% 100%);
		clip-path: polygon(60% 0, 55% 0, 55% 100%, 60% 100%);
	}
	50% {
		-webkit-clip-path: polygon(30% 0, 31% 0, 31% 100%, 30% 100%);
		clip-path: polygon(30% 0, 31% 0, 31% 100%, 30% 100%);
	}
	53% {
		-webkit-clip-path: polygon(70% 0, 69% 0, 69% 100%, 70% 100%);
		clip-path: polygon(70% 0, 69% 0, 69% 100%, 70% 100%);
	}
	57% {
		-webkit-clip-path: polygon(40% 0, 41% 0, 41% 100%, 40% 100%);
		clip-path: polygon(40% 0, 41% 0, 41% 100%, 40% 100%);
	}
	60% {
		-webkit-clip-path: polygon(80% 0, 75% 0, 75% 100%, 80% 100%);
		clip-path: polygon(80% 0, 75% 0, 75% 100%, 80% 100%);
	}
	65% {
		-webkit-clip-path: polygon(50% 0, 51% 0, 51% 100%, 50% 100%);
		clip-path: polygon(50% 0, 51% 0, 51% 100%, 50% 100%);
	}
	70% {
		-webkit-clip-path: polygon(90% 0, 90% 0, 90% 100%, 90% 100%);
		clip-path: polygon(90% 0, 90% 0, 90% 100%, 90% 100%);
	}
	73% {
		-webkit-clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
		clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
	}
	80% {
		-webkit-clip-path: polygon(100% 0, 99% 0, 99% 100%, 100% 100%);
		clip-path: polygon(100% 0, 99% 0, 99% 100%, 100% 100%);
	}
	100% {
		-webkit-clip-path: polygon(70% 0, 71% 0, 71% 100%, 70% 100%);
		clip-path: polygon(70% 0, 71% 0, 71% 100%, 70% 100%);
	}
}

@keyframes glitch-anim-text {
	0% { 
		opacity: 1;
		transform: translate3d(-10px,0,0) scale3d(-1,-1,1);
		-webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
		clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
	}
	10% {
		-webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
		clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
	}
	20% {
		-webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
		clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
	}
	35% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
		clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
	}
	50% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
		clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
	}
	60% {
		-webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
		clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
	}
	70% {
		-webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
		clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
	}
	80% {
		-webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
		clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
	}
	90% {
		transform: translate3d(-10px,0,0) scale3d(-1,-1,1);
	}
	100% {
		opacity: 1;
		transform: translate3d(0,0,0) scale3d(1,1,1);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
}

/* Flash */
@keyframes glitch-anim-flash {
	0% { 
		opacity: 0.2; 
		transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
	}
	33%, 100% { 
		opacity: 0;
		transform: translate3d(0,0,0);
	}
}